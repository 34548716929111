<template>
<v-container fluid class="pa-0">
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-layout row wrap>
            <v-flex xs12 style="max-width:100%" v-if="sync_error">
                <v-card class="ma-2 pa-5 elevation-2">    
                <v-alert border="top" colored-border type="info" elevation="2" >
                    <v-list dense>
                        <v-subheader>Erreur</v-subheader>
                        <v-list-item-group color="primary" >
                            <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title >{{ sync_error_message }}</v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-alert>
                </v-card>
            </v-flex>
            <template v-for="(item, index) in syncRow">
            <v-flex xs10 class="pa-2" :key="index">
            <v-alert v-model="syncRow[index].display" dismissible 
                :color="syncRow[index].color" 
                border="left" elevation="2" colored-border :icon="(syncRow[index].sync) ? 'mdi-sync' : 'mdi-power-standby'" class="mb-1">
                {{ item.message }}
                <!-- <v-chip outlined color="blue">{{ locDateFormat.formatLocale('2020-01-01', 'fr') }}</v-chip> -->
                <template v-for="(itr, idxr) in item.result">
                    <span :key="idxr"><br />
                    <v-chip outlined color="green">{{itr.table}}</v-chip>
                    Source: {{itr.source}} | Synchro: {{itr.sync}} | Insert: {{itr.insert}}</span>
                </template>
            </v-alert>
            </v-flex>
            <v-flex xs2 class="pa-2" :key="index + 'start'">
                <v-btn v-show="!sync_global" class="mt-3" color="success" :loading="saveProgress" @click.native="syncTable(syncRow[index])" :disabled="saveProgress">
                    Demarrer
                </v-btn>
            </v-flex>
            </template>
        </v-layout>
    </v-card>

</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';

export default {
    name: 'import-synchro',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            sync_progress: 0,
            sync_global  : 0,
            sync_complete: false,
            sync_error: false,
            sync_error_message: '',
            locDateFormat: dateLocale, // Format de date
            activeTab: 0,
            processInfo: { error : [ 'Information sur le traitement...' ]},
            form: {
                file_task: null,     file_task_name: '',     file_task_source: '',
                file_specification: null, file_specification_name: '', file_specification_source: '',
            },
            syncRow : [
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/towns/sync/?limit=100', message: 'Mairies' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/towns/syncgouv/', message: 'Mairies (infos)' },
                { display: true, sync: false, color: 'grey', result: {}, scope: ['all'], endPoint: '/towns/sync/?limit=100&mode=postalcode', message: 'Code post' },

            ],
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.form.sync_progress.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.sync_progress / 50)]
        }
    },
    mounted:function (){

    },
    methods: {
        getFile(file, scope){
            if( this.form['file_' + scope + '_name'].length == 0 ){
                let nameTab = file.name.split(".")
                this.form['file_' + scope + '_name'] = nameTab[0]
            }
            this.form['file_' + scope + '_name'] = file.name
        },
        async sendSynchFile(scope) {
            // Enregistrement du formulaire
            this.saveProgress = true
            this.$http.get( scope + '/' ).then( (response) => {
                this.$root.$toast({ color: 'success', text: 'Données enregistrée !' })
                this.saveProgress = false
                for( let prop in response.data ){
                    if( Array.isArray( response.data[prop] ) ){
                        this.processInfo[prop] = response.data[prop]
                    } else {
                        this.processInfo[prop] = response.data[prop]
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
            })
        },
        syncTable(table){
            this.$http.get( table.endPoint ).then( (response) => {
                this.$root.$toast({ color: 'success', text: 'Données enregistrée !' })
                this.saveProgress = false
                console.log(response)
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
            })
        }
    },
    components: { }
}
</script>